import { Col, Select } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLegalEntity } from 'redux/selectedLegalEntity/selectedLegalEntitySlice';
import { grannyBirdApi } from 'redux/services/grannyBird';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

export const EntitySelector: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const legalEntityCookie = Cookies.get('legalEntity');

  const { data: legalEntityData } = useGetLegalEntitiesQuery();

  useEffect(() => {
    if (!legalEntityCookie) {
      Cookies.set(`legalEntity`, 'MDSI', { expires: 365 });
      dispatch(setLegalEntity('MDSI'));
    }
  }, [dispatch, legalEntityCookie]);

  const handleChange = (event: string): void => {
    if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
      Cookies.set(`legalEntity`, event, { expires: 365 });
      dispatch(setLegalEntity(event));
    } else {
      Cookies.set(`legalEntity`, event, { expires: 365, domain: 'mdsiinc.com' });
      dispatch(setLegalEntity(event));
    }
    dispatch(grannyBirdApi.util.resetApiState());
    history.push('/');
  };

  const entityNameOption: any = legalEntityData?.data?.map((entity) => ({ value: entity.dataAreaId, label: entity.dataAreaId }));

  return (
    <Col>
      <Select defaultValue={legalEntityCookie === undefined ? 'MDSI' : legalEntityCookie} onChange={handleChange} style={{ width: 150 }} options={entityNameOption} />
    </Col>
  );
};
