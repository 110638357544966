import { Col, Row } from 'antd';
import { CustomerNumberFilter } from 'components/atoms/Filters/CustomerNumberFilter';
import { DateRangeFilter } from 'components/atoms/Filters/DateRangeFilter';
import { PONumberFilter } from 'components/atoms/Filters/PONumberFilter';
import VendorFilter from 'components/atoms/Filters/VendorFilter';
import { WarehouseFilter } from 'components/atoms/Filters/WarehouseFilter';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();

  const isRecyclingBinPage = pathname.includes('recycling-bin');

  return (
    <>
      {!isRecyclingBinPage && (
        <Row gutter={[16, 0]} justify="start" style={{ padding: '20px 10px', backgroundColor: 'white', marginBottom: 20 }}>
          <Col>
            <PONumberFilter />
          </Col>
          <Col>
            <CustomerNumberFilter />
          </Col>
          <Col>
            <VendorFilter />
          </Col>
          <Col>
            <WarehouseFilter />
          </Col>
          <Col>
            <DateRangeFilter />
          </Col>
        </Row>
      )}
    </>
  );
};
