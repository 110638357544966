import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GrannyBirdBinParams {
  offset: number;
  locationIdContains?: string;
}

const initialState: GrannyBirdBinParams = {
  offset: 0,
  locationIdContains: undefined
};

export const binParams = createSlice({
  name: 'grannyBirdBinParams',
  initialState,
  reducers: {
    setLocationIdContains: (state, { payload }: PayloadAction<GrannyBirdBinParams['locationIdContains']>): void => {
      state.locationIdContains = payload;
      state.offset = 0;
    },
    increaseOffset: (state): void => {
      state.offset += 25;
    }
  }
});

export const { increaseOffset, setLocationIdContains } = binParams.actions;
