import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increaseOffset, setLocationIdContains } from 'redux/services/grannyBird/binParams';
import { useGetBinLocationsQuery } from 'redux/services/grannyBird/grannyBirdApi';
import { ReduxState } from 'redux/store';

interface Props extends SelectProps {
  warehouseId: string;
}

const BinSelector: React.FC<Props> = ({ warehouseId, ...rest }: Props): JSX.Element => {
  const { grannyBirdBinParams } = useSelector((state: ReduxState) => state);
  const { data, isLoading, isFetching } = useGetBinLocationsQuery({ params: grannyBirdBinParams, warehouseId });

  const options: DefaultOptionType[] | undefined = data?.data.map((binLocation) => ({ value: binLocation, label: binLocation }));
  const dispatch = useDispatch();

  const handleSearch = (value: string): void => {
    dispatch(setLocationIdContains(value));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    if (event.currentTarget.scrollHeight === event.currentTarget.scrollTop + event.currentTarget.offsetHeight) {
      dispatch(increaseOffset());
    }
  };

  const handleSearchClear = (): void => {
    dispatch(setLocationIdContains(undefined));
  };

  return (
    <Select
      {...rest}
      onClear={handleSearchClear}
      showSearch
      loading={isFetching || isLoading}
      options={options}
      onSearch={handleSearch}
      onPopupScroll={handleScroll}
      allowClear
      style={{ width: '100%' }}
    />
  );
};

export default BinSelector;
