import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPurchaseOrderNumberContains } from 'redux/services/grannyBird/poReceiptParams';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FancyInput } from '../Inputs';

export const PONumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();

  /* ******************** Functions ******************** */
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   const { value } = event.target;

  //   dispatch(setPurchaseOrderNumberContains(value));
  // };

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setPurchaseOrderNumberContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setPurchaseOrderNumberContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="PO Number" onChange={handleChange} />;
};
