import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';

const styles: InlineStylesModel = {
  formLabel: {
    marginBottom: 0
  }
};

interface Props extends TitleProps {
  label: string;
}

// eslint-disable-next-line react/prop-types
export const FormLabel: React.FC<Props> = ({ label }) => {
  return <Typography.Text style={styles.formLabel}>{label}</Typography.Text>;
};
