import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerOrderNumberContains } from 'redux/services/grannyBird/poReceiptParams';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FancyInput } from '../Inputs';

export const CustomerNumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setCustomerOrderNumberContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerOrderNumberContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="CO Number" onChange={handleChange} />;
};
