import { Button, Col, Row, Space, Typography } from 'antd';
import { POReceiptModel } from 'models/POReceiptModel';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFetchLineError, setSelectedLine, setSelectedMenu, setStagedTable } from 'redux/poReceipts/po-receipts-slice';

interface Props {
  poReceipt: POReceiptModel;
}

export const Breadcrumbs: React.FC<Props> = ({ poReceipt }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackButton = (): void => {
    history.push('/');
    dispatch(setSelectedMenu('Main'));
    dispatch(setStagedTable(null));
    dispatch(setSelectedLine(null));
    dispatch(setFetchLineError(null));
  };

  return (
    <Row justify="space-between" align="top" style={{ marginBottom: 16, marginTop: 8 }}>
      <Row gutter={8}>
        <Col>
          <Space>
            <Typography.Text style={{ fontWeight: 'bold', color: 'rgb(47, 85, 150)' }}>Purchase Order: </Typography.Text>
            <Typography.Text>{poReceipt?.purchaseOrderNumber}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={{ fontWeight: 'bold', color: 'rgb(47, 85, 150)' }}>Vendor: </Typography.Text>
            <Typography.Text>{poReceipt?.vendorAccount}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={{ fontWeight: 'bold', color: 'rgb(47, 85, 150)' }}>Vendor Reference: </Typography.Text>
            <Typography.Text>{poReceipt?.vendorOrderReference}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Col>
        <Button onClick={onBackButton}>Back</Button>
      </Col>
    </Row>
  );
};
