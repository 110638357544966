import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  selectedLegalEntity: Cookies.get('legalEntity') ?? ''
};

export const selectedLegalEntitySlice = createSlice({
  name: 'selectedLegalEntity',
  initialState,
  reducers: {
    setLegalEntity: (state, action: PayloadAction<string>): void => {
      state.selectedLegalEntity = action.payload;
    }
  }
});

export const { setLegalEntity } = selectedLegalEntitySlice.actions;
export const selectedLegalEntityReducer = selectedLegalEntitySlice.reducer;
