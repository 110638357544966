import { Descriptions } from 'antd';
import { POReceiptModel } from 'models/POReceiptModel';
import React from 'react';

interface Props {
  poReceipt: POReceiptModel;
}

export const POHeader: React.FC<Props> = ({ poReceipt }: Props) => {
  return (
    <Descriptions labelStyle={{ fontWeight: 'bold' }} size="small" column={10} layout="vertical" bordered>
      <Descriptions.Item label="Warehouse">{poReceipt.warehouse || 'N/A'}</Descriptions.Item>
      <Descriptions.Item label="Direct Delivery">{poReceipt.directDelivery ? 'Yes' : 'No'}</Descriptions.Item>
      <Descriptions.Item label="Delivery Mode">{poReceipt.deliveryMode || 'N/A'}</Descriptions.Item>
      <Descriptions.Item label="Requested By">{poReceipt.requestedBy || 'N/A'}</Descriptions.Item>
      <Descriptions.Item label="Ordered By">{poReceipt.orderedBy || 'N/A'}</Descriptions.Item>
    </Descriptions>
  );
};
